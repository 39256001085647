import Button, { ButtonProps } from '~/components/interactive/Button';
import { ReactNode, useState } from 'react';
import Modal from '~/components/interactive/Modal';

export default function ModalButton(
  props: Omit<ButtonProps, 'onClick' | 'children'> & { text: string; children: ReactNode; hash?: string },
) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        {...props}
        onClick={() => {
          setShow(true);
        }}
      >
        {props.text}
      </Button>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        {props.children}
      </Modal>
    </>
  );
}
